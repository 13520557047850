/* HEADER SECTION  */
.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--body-color);
    z-index: var(--z-fixed);
    transition: 0.5s;
    box-shadow: 0px 20px 34px 0px rgba(0, 41, 158, 0.1);
  
    -webkit-box-shadow: 0px 20px 34px 0px rgba(0, 41, 158, 0.1);
  }
  
  .header_toggle {
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .header ul {
    border: none;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  }
  
  .avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    object-fit: cover;
  }
  
  /* SIDE BAR  */
  .sidebar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color: var(--body-color);
    padding: 0.5rem 1rem 0 0;
    transition: 1.5s;
    z-index: var(--z-fixed);
    -webkit-box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 1px -1px 5px 0px rgba(0, 0, 0, 0.1);
  }
  
  .admin-dash-nav {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .nav_logo,
  .nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
    padding: 0.5rem 0 0.5rem 1.5rem;
  }
  
  /* .nav_logo {
    margin-bottom: 2rem;
  } */
  .logo {
    width: 3rem;
    height: 3rem;
  }
  .nav_logo-icon {
    font-size: 1.25rem;
    color: var(--purple-color);
  }
  
  .nav_logo-name {
    color: var(--first-color);
    font-weight: 700;
  }
  
  .nav_link {
    position: relative;
    color: var(--first-color-light);
    margin-bottom: 0.5rem;
    transition: 0.3s;
  }
  
  .nav_link:hover {
    color: var(--green-color);
    background-color: var(--light-blue-color);
    border-radius: 0.7rem;
  }
  
  .nav_icon {
    font-size: 1.25rem;
    color: var(--grey-color);
  }
  .nav_name {
    cursor: pointer;
  }
  .nav_link.active {
    color: var(--blue-color);
    font-weight: 700;
    background-color: var(--light-blue-color);
    border-radius: 0.7rem;
  }
  
  .review {
    left: 0;
  }
  
  .add_body_padding {
    padding-left: calc(var(--nav-width) + 1rem);
    transition: 1.5s;
  }
  .main {
    transition: 1.5s;
  }
  
  @media screen and (min-width: 768px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem);
    }
  
    .header {
        /* height: calc(var(--header-height) + 1rem); */
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
    }
  
    .header_img {
        width: 40px;
        height: 40px;
    }
  
    .header_img img {
        width: 45px;
    }
  
    .sidebar {
        left: 0;
        padding: 1rem 1rem 0 0;
    }
  
    .review {
        width: calc(var(--nav-width) + 9.75rem);
    }
  
    .add_body_padding {
        /* padding-left: calc(var(--nav-width) + 11.75rem); */
        padding-left: calc(var(--nav-width) + 5.75rem);
    }
  }
  