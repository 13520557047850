.display-4{
    font-weight: bold;
    color: white;
    opacity: 0.4;
}

.card-body-icon{

    position: absolute;
    color: white;
    z-index: 0;
    top: 25px;
    right: 4px;
    opacity: 0.4;
    font-size: 90px;
}
/* 
.card-body:hover >.display-4{

    transform: scaleY(1.5);
    transition: 0.5s;
} */
.card-body:hover >.card-body-icon{

    transform: scaleY(1.5);
    transition: 0.5s;
}