.sect5{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:#0d6efd;
    position: absolute;
    top: 0px;
}
 @media screen {
   
 }