.ImageBox{
    position: relative;
    height: 100%;
    width: 100%;
}
 img{
    height: 100%;
    width: 100%;
 }

 .container2{
    /* height: 100vh; */
    display: flex;
    align-items: center;
    
 }