.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

.pagination button:not(:last-child) {
  margin-right: 5px;
}

.pagination button:hover {
  background-color: #f0f0f0;
}
