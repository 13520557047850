.container1{
    width: 500px;
    height: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f8;
    border-radius: 14px;
    border: 1px solid black;
    padding: 5px;
    margin: 120px auto;
    
}