.scrollbar{
    overflow: auto;
    height: 100vh;
}

.scrollbar::-webkit-scrollbar{
    width: 5px;
   
}

.scrollbar::-webkit-scrollbar-track{
}

.scrollbar::-webkit-scrollbar-thumb{
    background-color:#cdcdcd;
    height: 10px;
    width: 5px;
    border-radius: 10px;
    
}

::-webkit-scrollbar-thumb:hover{
    background-color: #939598;
    transition: 0.5s;
}



.nav_link-open {
    margin-left: 5px; /* Adjust the margin as needed */
  }

  /* .content-open{
    margin-left: 30px;
  } */